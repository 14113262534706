@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./overrides.css";

:root {
  font-size: 16px;
}

html {
  @apply h-[100%] text-lg;
}

body,
#root {
  @apply min-h-[100%] h-[100%] flex flex-col bg-bg-main;
}
